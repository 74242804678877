import React from "react";
import './IndustryDesc.css';
export const Energymanagement = () => {
  return (
    <div className="App">
      <section className="intro">
        <h2>Digital Solutions</h2>
        <p>Achieve sustainability goals, optimize resource use, and reduce operational costs with our advanced Energy Management Solutions.</p> 
        <p className="mt-2">Our cutting-edge tools and technologies empower utility companies to monitor, analyze, and control energy consumption across their operations.</p> 
        <p className="mt-2">By harnessing the power of real-time data analytics and automation, you can enhance efficiency, minimize waste, and deliver reliable services to your customers.</p>
      </section>

      <section className="journey">
        <h2>Key Outcomes</h2>
        <div className="journey-content">
          <div className="journey-point">
            <h3>Real-Time Energy Monitoring</h3>
            <p>Our solution enables utility companies to monitor energy usage in real-time through IoT-enabled smart meters and sensors.</p> 
              <p>This feature provides actionable insights into consumption patterns, peak usage times, and potential areas for optimization, helping to reduce energy waste and costs.</p>
            
          </div>
          <div className="journey-point">
            <h3>Automated Load Balancing</h3>
            <p>Boost grid reliability and prevent outages with our cutting-edge load balancing platform.</p> 
            <p>Our solution intelligently distributes energy loads in real-time, aligning supply with demand to ensure seamless energy delivery and minimize infrastructure stress.</p>
          </div>
        </div>

        <div className="journey-content">
          <div className="journey-point">
            <h3>Renewable Energy Integration</h3>
            <p>Seamlessly integrate renewable energy sources like solar and wind power into your existing grid.</p> 
            <p>Our advanced algorithms optimize energy usage, minimizing reliance on fossil fuels and boosting your sustainability efforts.</p>
          </div>
          <div className="journey-point">
            <h3>Energy Efficiency Analytics</h3>
            <p>Harness the power of advanced analytics to uncover hidden energy waste.</p> 
            <p>Our platform identifies inefficiencies, provides actionable recommendations, and empowers organizations to implement energy-saving strategies.</p> 
            <p>Reduce costs, lower your carbon footprint, and build a more sustainable future.</p>
          </div>
        </div>

        <div className="journey-content">
          <div className="journey-point">
            <h3>Predictive Maintenance</h3>
              <p>Proactively Protect Your Energy Systems with AI-Powered Predictive Maintenance. </p> 
              <p>Identify potential equipment failures before they happen.</p> 
              <p>Reduce costly downtime, minimize repair expenses, and extend the lifespan of your energy assets with our AI-driven predictive maintenance solution. </p>
          </div>
          <div className="journey-point">
            <h3>Compliance and Reporting</h3>
              <p>Simplify Energy Compliance with Detailed Reporting. </p> 
              <p>Streamline your energy compliance efforts by generating comprehensive reports on energy usage, emissions, and efficiency metrics. </p> 
              <p>Ensure regulatory adherence and maintain transparency with our powerful solution.</p>
          </div>
        </div>
      </section>

      <section className="benefits">
        <h2>Benefits </h2>
        <ul>
          <li>
            <strong className="global-color">Enhanced Operational Efficiency</strong> <p>Elevate Your Energy Management with Real-Time Insights. Empower your energy management processes with real-time monitoring and analytics. Streamline operations, optimize resource utilization, and boost overall efficiency.</p>
          </li>
          <li>
            <strong className="global-color">Cost Savings</strong><p> Slash Costs and Boost Efficiency with Optimized Energy Usage. By optimizing energy usage and minimizing waste, organizations can unlock significant cost savings.</p>
          </li>
          <li>
            <strong className="global-color">Sustainability</strong> <p>Power Your Sustainability Goals with Renewable Energy and Efficiency. Harness the power of renewable energy sources and implement energy-efficient measures to reduce your carbon footprint and achieve your environmental goals.</p>
          </li>
          <li>
            <strong className="global-color">Reliability</strong> <p>Ensure Uninterrupted Energy Delivery with Predictive Maintenance and Automated Load Balancing. Proactively maintain your energy infrastructure and optimize load distribution with our advanced solutions. Prevent outages and maximize energy efficiency.</p>
          </li>
          <li>
            <strong className="global-color">Regulatory Compliance</strong> <p>Simplify Compliance, Mitigate Risks, and Enhance Your Reputation. Streamline your reporting processes and ensure adherence to energy standards. By minimizing the risk of penalties and boosting your reputation, you can focus on what truly matters.</p>
          </li>
        </ul>
      </section>

     
    </div>
  );
};


export const Waterutilities = () => {
  return (
    <div className="App">
      <section className="intro">
        <h2>Digital Solutions </h2>
        <p>Tackle resource scarcity, aging infrastructure, and rising demand with our advanced water management technologies.</p> 
        <p className="mt-2">Using real-time data, IoT devices, and automation, we help utilities optimize resources, reduce waste, and ensure reliable water services for their communities.</p> 
        <p className="mt-2">Drive efficiency and sustainability with our cutting-edge solutions.</p>
      </section>

      <section className="journey">
        <h2>Key Outcomes</h2>
        <div className="journey-content">
          <div className="journey-point">
            <h3>Smart Water Metering</h3>
            <p>
             Revolutionize your water management with our cutting-edge IoT-enabled smart water meters.</p> 
             <p>Empower utilities to monitor water consumption in real-time, detect leaks swiftly, and optimize billing accuracy.</p> 
             <p>Enhance customer satisfaction and conserve precious water resources with our innovative solution.</p>
          </div>
          <div className="journey-point">
            <h3>Leak Detection and Prevention</h3>
            <p>Transform your water distribution network with our intelligent leak detection system.</p>
            <p>By leveraging advanced sensor technology and powerful analytics, we quickly identify and resolve leaks, saving water, cutting costs, and improving service reliability.</p>
          </div>
        </div>

        <div className="journey-content">
          <div className="journey-point">
            <h3>Water Quality Monitoring</h3>
              <p>Protect Your Water Supply with Real-Time Monitoring. Safeguarding water quality is paramount.</p> 
              <p>Our innovative solution empowers you to proactively monitor critical water parameters like pH, turbidity, and chemical levels.</p> 
              <p>By deploying advanced sensors, we capture real-time data, identifying potential threats instantly.</p> 
                <p>Receive immediate alerts for any deviations, allowing you to swiftly respond and maintain compliance while safeguarding public health.</p>
          </div>
          <div className="journey-point">
            <h3>Demand Forecasting and Resource Planning</h3>
            <p>Our solution leverages historical and real-time data to accurately forecast future water demand trends.</p> 
            <p>By empowering utilities with these insights, we help them optimize resource allocation and infrastructure planning.</p> 
            <p>This proactive approach ensures they can meet future water needs effectively and efficiently.</p>
          </div>
        </div>

        <div className="journey-content">
          <div className="journey-point">
            <h3>Automated Pump and Valve Control</h3>
            <p>Optimize Water Distribution with Automated Pump and Valve Control.</p> 
            <p>Revolutionize your water distribution network by automating the control of pumps and valves.</p> 
            <p> Maximize energy efficiency and minimize equipment wear and tear while maintaining optimal water pressure and flow.</p>
          </div>
          <div className="journey-point">
            <h3>Regulatory Compliance and Reporting</h3>
            <p>Simplify Water Compliance with Automated Data Collection and Reporting. </p> 
            <p>Streamline your water compliance efforts by automating the collection and reporting of crucial data, including water usage, quality metrics, and infrastructure performance. </p> 
            <p>Ensure transparency and achieve regulatory adherence with our solution. </p>
          </div>
        </div>
      </section>

      <section className="benefits">
        <h2>Benefits</h2>
        <ul>
          <li>
            <strong className="global-color">Enhanced Efficiency</strong> <p>Optimize Water Usage with Real-time Monitoring and Automation. Reduce water waste and maximize resource utilization with our real-time monitoring and automation solutions.</p>
          </li>
          <li>
            <strong className="global-color">Cost Savings</strong> <p>Reduce Costs and Boost Efficiency with Early Leak Detection and Optimized Energy Use. Identify potential issues early with our advanced leak detection technology, minimize downtime, and lower operational costs. Optimize energy consumption and maximize savings with our intelligent energy management solutions.</p>
          </li>
          <li>
            <strong className="global-color">Improved Water Quality</strong> <p>Safeguard Your Water Supply with Continuous Monitoring. Ensure the safety and compliance of your water supply with our advanced continuous monitoring solutions. Protect your customers and minimize risks with our reliable and efficient system.</p>
          </li>
          <li>
            <strong className="global-color">Reliable Service Delivery</strong> <p>Ensure Uninterrupted Water Services with Predictive Analytics. Proactively maintain your water infrastructure and optimize demand forecasting with our predictive maintenance solutions. Safeguard your water services and reduce operational costs.</p>
          </li>
          <li>
            <strong className="global-color">Regulatory Compliance</strong> <p>Streamline Data Collection and Reporting for Regulatory Compliance. Simplify your data collection and reporting processes to ensure seamless compliance with legal and environmental standards. Our solution empowers utilities to efficiently manage data, reduce errors, and improve overall operational efficiency.</p>
          </li>
        </ul>
      </section>

      
    </div>
  );
};

export const OilAndGas = () => {
  return (
    <div className="App">
      <section className="intro">
        <h2>Digital Solutions </h2>
        <p className="mt-2">Modernize Your Operations with VRIO Digital Solutions.</p>
        <p className="mt-2">The oil and gas industry operates in a dynamic and highly competitive environment.</p> 
        <p className="mt-2">To remain competitive and ensure long-term sustainability, you need to adopt innovative solutions that optimize operations, enhance safety, and minimize environmental impact.</p> 
        <p className="mt-2">Our tailored digital solutions, leveraging cutting-edge technologies such as IoT, data analytics, and automation, enable you to streamline processes, increase efficiency, and support sustainability initiatives.</p>
      </section>

      <section className="journey">
        <h2>Key Outcomes</h2>
        <div className="journey-content">
          <div className="journey-point">
            <h3>Asset Performance Management</h3>
            <p>Maximize equipment uptime and lifespan with real-time monitoring and predictive analytics.</p> 
            <p>Our solution ensures timely maintenance, reduces downtime, and optimizes asset utilization across upstream, midstream, and downstream operations.</p>
          </div>
          <div className="journey-point">
            <h3>Pipeline Monitoring and Leak Detection</h3>
            <p>Protect your pipelines with cutting-edge IoT and AI. </p>
            <p> Our system monitors your pipelines 24/7, detecting leaks, identifying pressure anomalies, and predicting corrosion.</p> 
            <p>React swiftly to threats, minimizing environmental damage and maximizing operational efficiency.</p>
          </div>
        </div>

        <div className="journey-content">
          <div className="journey-point">
            <h3>Production Optimization</h3>
            <p>Revolutionize your production efficiency with data-driven insights.</p> 
            <p>Unleash the power of operational data to pinpoint bottlenecks, supercharge well performance, and streamline resource utilization.</p> 
            <p>Experience the transformative impact of our solution.</p>
          </div>
          <div className="journey-point">
            <h3>Health, Safety, and Environment (HSE) Management</h3>
            <p>Revolutionize your HSE processes with our automated solution.</p>
            <p>Streamline incident reporting, optimize safety inspections, and enhance environmental monitoring.</p> 
            <p>By automating these critical tasks, you can ensure regulatory compliance and fulfill your corporate responsibility goals.</p>
          </div>
        </div>

        <div className="journey-content">
          <div className="journey-point">
            <h3>Supply Chain and Logistics Optimization</h3>
            <p>Accelerate Your Supply Chain with Real-Time Insights.</p> 
            <p>Streamline your supply chain operations with our platform.</p> 
            <p>Gain real-time visibility into your inventory and shipments.</p> 
            <p>Predict future demand and optimize your procurement, transportation, and distribution strategies.</p> 
            <p>By minimizing delays and reducing costs, you can deliver products on time, every time.</p>
          </div>
          <div className="journey-point">
            <h3>Energy Transition and Sustainability</h3>
            <p>Accelerate Your Sustainable Journey with Carbon-Reducing Solutions.</p> 
            <p>Empower your business to monitor and optimize energy consumption.</p> 
            <p>Integrate renewable energy sources seamlessly into your operations.</p> 
            <p>Reduce your carbon footprint and build a sustainable future.</p>
          </div>
        </div>
      </section>

      <section className="benefits">
        <h2>Benefits</h2>
        <ul>
          <li>
            <strong className="global-color">Enhanced Operational Efficiency</strong> <p>Accelerate Your Workflow with Data-Driven Optimization. Streamline your processes, eliminate bottlenecks, and maximize efficiency with our powerful data-driven optimization tools.</p>
          </li>
          <li>
            <strong className="global-color">Cost Savings</strong> <p>Maximize Savings with Predictive Maintenance & Efficient Resource Allocation. Reduce operational costs and optimize resource utilization with our cutting-edge predictive maintenance solutions.</p>
          </li>
          <li>
            <strong className="global-color">Improved Safety and Compliance</strong> <p>Mitigate Risks and Safeguard Your Operations. Proactively identify and address potential risks to safeguard your operations and ensure compliance with stringent safety and environmental standards.</p>
          </li>
          <li>
            <strong className="global-color">Sustainability</strong> <p>Combat Climate Change and Cut Costs with Clean Energy Solutions. Reduce your carbon footprint and lower energy costs by embracing cleaner energy solutions.</p>
          </li>
          <li>
            <strong className="global-color">Increased Resilience</strong> <p>Navigate Market Volatility with Advanced Monitoring and Analytics. Empower your business to adapt to market fluctuations and overcome operational challenges with our advanced monitoring and analytics solution.</p>
          </li>
        </ul>
      </section>

      
    </div>
  );
};

export const HazardousWasteManagement = () => {
  return (
    <div className="App">
      <section className="intro">
        <h2>Digital Solutions</h2>
        <p>
        Ensure safety, compliance and environmental protection with our advanced hazardous waste management solutions.
        </p>
        <p className="mt-2">Streamline processes, enhance tracking and minimize risks to people and the environment.</p>
        <p className="mt-2">Our solutions help industries meet regulatory requirements and achieve operational excellence.</p>
      </section>

      <section className="journey">
        <h2>Key Outcomes</h2>
        <div className="journey-content">
          <div className="journey-point">
            <h3>Waste Tracking and Documentation</h3>
            <p>Track and Trace Hazardous Waste with Precision.</p>

            <p> Leverage real-time tracking to monitor hazardous waste from generation to disposal.</p> 

            <p>We ensure proper documentation of all activities, meeting regulatory requirements and providing complete transparency.</p>
          </div>
          <div className="journey-point">
            <h3>Compliance Management</h3>
            <p>
            Simplify your regulatory journey with automated workflows. 
            </p>
            <p>Effortlessly navigate local and international regulations by automating permit applications, report generation and audit preparation. </p>
            <p>Reduce manual effort, minimize errors and ensure timely submissions. </p>
            <p>By automating compliance tasks, you can lower the risk of costly fines and penalties. </p>
            <p>We empower you to focus on strategic initiatives while maintaining regulatory adherence.</p>
            
          </div>
        </div>

        <div className="journey-content">
          <div className="journey-point">
            <h3>Risk Mitigation</h3>
            <p>
            Revolutionize hazardous material management with our cutting-edge predictive analytics. 
            </p>
            <p>Identify potential risks, implement preventive measures and ensure safer handling, storage and transportation.</p>
            <p>Optimize your safety protocols and compliance efforts today.</p>
          </div>
          <div className="journey-point">
            <h3>Sustainable Disposal Practices</h3>
            <p>Accelerate your journey to sustainable waste management. </p>
             <p>Implement innovative solutions like waste-to-energy conversion and recycling. 
             </p>
             <p>Meet compliance requirements and positively impact the environment.</p>
          </div>
        </div>
      </section>

      <section className="benefits">
        <h2>Benefits</h2>
        <ul>
          <li><strong className="global-color">Safeguard Your Operations</strong><p>Optimize Hazardous Material Handling & Disposal. Mitigate Risks, Protect the Planet. Ensure the safe and compliant handling and disposal of hazardous materials. We help you minimize risks to personnel and the environment, streamlining your processes and enhancing overall safety.</p></li>
          <li><strong className="global-color">Regulatory Compliance</strong> <p>Simplify Compliance and Streamline Workflows. Automate your compliance workflows to conquer stringent regulations with ease. Accelerate your processes and reduce the risk of human error.</p></li>
          <li><strong className="global-color">Operational Efficiency</strong> <p>Simplify Hazardous Waste Management. Streamline Tracking and Documentation. Reduce time and costs associated with hazardous waste management by streamlining tracking and documentation processes.</p></li>
          <li><strong className="global-color">Sustainability</strong> <p>Promote Sustainability and Eco-Friendly Practices. Support eco-friendly disposal methods and align your organization with global sustainability goals.</p></li>
        </ul>
      </section>

    </div>
  );
};


export const ElectricUtilities = () => {
  return (
    <div className="App">
      <section className="intro">
        <h2>Digital Solutions</h2>
        <p>Power Your Utility's Future with Digital Transformation.</p> 
        <p className="mt-2">Electric utilities face increasing pressure to deliver reliable, efficient, and sustainable energy.</p> 
        <p className="mt-2">Our tailored digital solutions, powered by advanced technologies, empower you to optimize grid performance, enhance customer experiences, and accelerate the transition to renewable energy.</p> 
        <p className="mt-2">Transform your utility today.</p>
      </section>

      <section className="journey">
        <h2>Key Outcomes</h2>
        <div className="journey-content">
          <div className="journey-point">
            <h3>Smart Grid Management</h3>
            <p>Enhance grid reliability and performance with real-time monitoring and predictive maintenance.</p> 
            <p>Our solutions enable utilities to detect and resolve issues proactively, reducing outages and improving service quality.</p>
          </div>
          <div className="journey-point">
            <h3>Energy Efficiency Programs</h3>
            <p>Empower your customers to take control of their energy consumption with our innovative tools. </p> 
             <p>Monitor energy usage in real-time, identify areas for improvement, and implement strategies to reduce costs and environmental impact. </p> 
             <p>Our platform actively supports demand-side management initiatives, driving energy efficiency and fostering a sustainable future. </p>
          </div>
        </div>

        <div className="journey-content">
          <div className="journey-point">
            <h3>Renewable Energy Integration</h3>
           <p>Power Your Grid's Future with Seamless Renewable Integration. </p> 
             <p>Accelerate the seamless integration of renewable energy sources into the grid. </p> 
             <p>Our solutions empower distributed energy resource (DER) management, balancing your energy mix for a sustainable future. </p>
          </div>
          <div className="journey-point">
            <h3>Advanced Customer Engagement</h3>
            <p>Empower your customers to take control of their energy usage. </p> 
            <p>Our platform enables them to effortlessly access account details, track energy consumption, and discover cost-saving opportunities through personalized insights, self-service portals, and user-friendly mobile apps.</p> 
            <p>Elevate Customer Experiences with Personalized Insights and Self-Service Tools. </p>
          </div>
        </div>
      </section>

      <section className="benefits">
        <h2>Benefits</h2>
        <ul>
          <li><strong className="global-color">Enhanced Grid Reliability</strong>
          <p> Maximize Uptime and Power Delivery with Proactive Maintenance. Eliminate downtime and guarantee consistent power delivery with our real-time monitoring and proactive maintenance solution.</p>

          </li>
          
          <li><strong className="global-color">Cost Efficiency</strong>
          <p>Elevate Energy Distribution & Slash Costs with AI-Powered Solutions. Maximize energy distribution efficiency and minimize operational costs with our cutting-edge analytics and automation tools.</p> </li>
          <li><strong className="global-color">Sustainability</strong> 
          <p>Accelerate Your Green Journey Empowering the Shift to Renewable Energy. Join the movement toward a sustainable future. We're committed to supporting the transition to renewable energy sources and promoting energy-efficient practices among our customers.</p></li>
          <li><strong className="global-color">Improved Customer Experience</strong> 
          <p>Elevate Customer Experiences with Personalized, Seamless Services. Delight your customers with tailored services that anticipate their needs. Our solution helps you deliver exceptional experiences that exceed expectations.</p></li>
        </ul>
      </section>
    </div>
  );
};
