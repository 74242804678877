import React from "react"
import vriologo from '../../Assets/vrio.jpg'
const VrioImages = () => {
    return(
        <div>
             <img src={vriologo} alt="vriodigital" />
        </div>
    )
}


export default VrioImages;

